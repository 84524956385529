<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    :name="DIAGNOSIS_MODALS_NAMES.insurerPortalModal"
    :title="i18n(translations.title)"
  >
    <template #body>
      <p class="body-1">
        {{ i18n(translations.content) }}
      </p>
    </template>

    <template #footer>
      <RevButton
        class="mb-12"
        data-qa="goto-insurer-portal"
        full-width="always"
        :to="insurerPortalUrl"
        variant="primary"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { dsTranslations } from '@ds/translations'

import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './InsurerPortalModal.translations'
import type { InsurerPortalModalProps } from './InsurerPortalModal.types'

withDefaults(defineProps<InsurerPortalModalProps>(), {
  insurerPortalUrl: null,
})

const i18n = useI18n()
</script>
