<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    :name="DIAGNOSIS_MODALS_NAMES.preShippingInvoiceRequestModal"
    :title="i18n(translations.title)"
  >
    <template #body>
      <p class="body-1 mb-16">
        {{ i18n(translations.content1) }}
      </p>
      <p class="body-1">
        {{ i18n(translations.content2) }}
      </p>
    </template>

    <template #footer>
      <RevButton
        class="mb-12"
        data-qa="goto-my-order"
        full-width="always"
        :to="myOrdersLink"
        variant="primary"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { dsTranslations } from '@ds/translations'

import { DASHBOARD } from '@/scopes/dashboard/routes'

import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './PreShippingInvoiceRequestModal.translations'

const i18n = useI18n()

const injectLocale = useRouteLocationWithLocale()

const myOrdersLink = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: DASHBOARD.MY_ORDERS.SELF,
  }),
)
</script>
